import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { Link, graphql } from 'gatsby';
import TimelineHeader from '../modules/timeline/components/TimelineHeader';
import { EnterButton } from '../modules/timeline/components/EnterButton';
const TimelinePre = (props) => {
  const {
    data: { bgWatch },
  } = props;

  return (
    <div className="timeline-pre">
      <div className="timeline-overlay"></div>
      <TimelineHeader>
        <BackgroundImage
          className="bg-image"
          Tag="section"
          fluid={bgWatch.childImageSharp.fluid}
          preserveStackingContext
        >
          <div className="content-container">
            <h2 className="fade-in heading-text">Explore Different people’s lifestyle and their milestone</h2>

            <div>
              <Link className="enter-link" to={'/timeline-details'}>
                <EnterButton />
              </Link>
            </div>
          </div>
        </BackgroundImage>
      </TimelineHeader>
    </div>
  );
};

export default TimelinePre;

export const query = graphql`
  {
    bgWatch: file(relativePath: { eq: "bg_timeline.JPG" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
