import React from 'react';
import Cursor from './cursor';
import ButtonCtrl from './buttonCtrl';
import './index.css';
export const EnterButton = () => {
  React.useEffect(() => {
    if (typeof window !== undefined) {
      const cursor = new Cursor(document.querySelector('.cursor'));
      const button = new ButtonCtrl(document.querySelector('.animate-button'));
      button.on('enter', () => cursor.enter());
      button.on('leave', () => cursor.leave());
    }
  }, []);

  return (
    <div>
      <div className="content">
        <button className="animate-button">
          <div className="animate-button__deco">
            <div className="animate-button__deco-filler"></div>
          </div>
          <span className="animate-button__text">
            <span className="animate-button__text-inner">ENTER</span>
          </span>
        </button>
        <svg id="cursor" className="cursor" width="25" height="25" viewBox="0 0 25 25">
          <circle className="cursor__inner" cx="12.5" cy="12.5" r="6.25" />
        </svg>
      </div>
    </div>
  );
};
