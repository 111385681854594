import React from 'react';
import BodyClassName from 'react-body-classname';
import DefaultLayoutComponent from '../../layouts/DefaultLayoutComponent';

const TimelineHeader = ({ seo, children, isForTimelineGraph }) => {
  return (
    <BodyClassName className="body-dark page-timeline">
      <DefaultLayoutComponent timeline isForTimelineGraph={isForTimelineGraph} title="Timeline" seo={seo}>
        {children}
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default TimelineHeader;
